import chalk from "chalk";
import pino from "pino";

import { ENVIRONMENT } from "../constants";
import { DEBUG } from "../constants";

const isLocalEnvironment =
  ENVIRONMENT === "development" || ENVIRONMENT === "local";
const isClientSide = typeof window !== "undefined";

const clientLogger = pino({
  browser: {
    write: (o: any) => {
      console.log(o);
    },
  },
  level: isLocalEnvironment ? "debug" : "info",
});

const serverLogger = pino({
  level: isLocalEnvironment ? "debug" : "info",
  formatters: {
    // use chalk to colorize the log level
    level: (label) => {
      return { level: label };
    },
  },
  // Use timestamp for better log readability
  timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
  // Minimize noise in logs
  base: undefined,
  // Use a custom format for the log message
});

const logger = isClientSide ? clientLogger : serverLogger;

const debugLogger = (...args: any) => {
  logger.debug(args);
};

const infoLogger = (...args: any) => {
  logger.info(args);
};

const warnLogger = (...args: any) => {
  logger.warn(args);
};

const errorLogger = (...args: any) => {
  logger.error(args);
};

export type LogProps = {
  message: string;
  event?: {
    name?: string;
    destination?: string;
    meta?: object;
  };
  // eslint-disable-next-line array-callback-return
  // `unknown` is safer than `any`
  // https://devblogs.microsoft.com/typescript/announcing-typescript-4-0/#unknown-on-catch
  error?: unknown;
  status?: Status;
  debugStatus?: boolean;
  // make any
  misc?: unknown;
};

type Status = "debug" | "info" | "warn" | "error";

type Window = typeof window & {
  DD_LOGS: {
    logger: {
      log: Function;
      setHandler: Function;
    };
    onReady: Function;
  };
};

/**
 * Send "debugging" messages to the console.
 *
 * **Note:** Not meant for "production" environments; use `logError` or `logWarning` instead.
 *
 * @param {string} message Debugging message to print
 * @param {object} event Relevant event data to print
 */
export function logDebug({
  message,
  event,
  debugStatus = DEBUG,
}: Omit<LogProps, "error" | "status">) {
  if (debugStatus) {
    debugLogger(`${chalk.blue("Debug:")} ${message}`, event || "");
  }
  datadogLog({ message, event, status: "debug" });
}

/**
 * Send "error" message to console,
 *
 * @param {string} message Error message to print
 * @param {object} event Relevant event data to print
 * @param {unknown} error The error object.
 */
export function logError({ message, event, error }: Omit<LogProps, "status">) {
  errorLogger(`${chalk.red("Error:")} ${message}`, event || "", error || "");

  datadogLog({ message, event, status: "error", error });
}

/**
 * Send "warning" message to console,
 *
 * @param {string} message Error message to print
 * @param {object} event Relevant event data to print
 * @param {unknown} error The error object.
 */
export function logWarning({
  message,
  event,
  error,
}: Omit<LogProps, "status">) {
  warnLogger(`${chalk.yellow("Warn:")} ${message}`, event || "", error || "");

  datadogLog({ message, event, status: "warn", error });
}

/**
 * Send "warning" message to console,
 *
 * @param {string} message Error message to print
 * @param {object} event Relevant event data to print
 * @param {unknown} error The error object.
 */
export function logInfo({
  message,
  event,
  error,
  misc = {},
}: Omit<LogProps, "status">) {
  infoLogger(
    `${chalk.yellow("Info:")} ${message}`,
    event || "",
    error || "",
    misc,
  );

  datadogLog({ message, event, status: "info", error });
}

/**
 * Call Datadog's generic logger function.
 * https://docs.datadoghq.com/logs/log_collection/javascript/#generic-logger-function
 *
 * @param {string} message The message of your log that is fully indexed by Datadog.
 * @param {object} event Additional event data to be sent with your log.
 * @param {string} status The status of your log. It can be one of the following: debug, info, warn, error.
 * @param {unknown} error The error object.
 */
export function datadogLog({
  message,
  event,
  status,
  error,
}: Pick<LogProps, "message" | "event" | "status" | "error">) {
  // If the `window` object is not available, then the log is coming from a server component and can't be sent to Datadog's client side logger.
  if (typeof window === "undefined") return;

  infoLogger(message, event, status, error);

  (window as Window).DD_LOGS?.onReady(function () {
    (window as Window).DD_LOGS.logger.log(
      message,
      /**
       * Organize event data into a single object for readability in Datadog
       * https://docs.datadoghq.com/logs/log_collection/javascript/#log-object
       * accomodating for undefined event data
       */
      typeof event === "object" ? { event: { ...event } } : { event: {} },
      status,
      typeof error === "string" ? new Error(error) : error,
    );
  });
}
